.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.planerBackend{
    --column-gap: 3rem;
    --row-gap: 0.2rem;

    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-row-gap: var(--row-gap);
    grid-column-gap: var(--column-gap);
    grid-auto-flow: column;

    margin: 0.5rem;
    font-size: 1rem;
    height:calc(100vh - 1rem);
}

.BPbox{
    overflow-y:auto;
}

.bpMain {
    --column-gap: 3rem;
    --row-gap: 0.2rem;

    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-row-gap: var(--row-gap);
    grid-column-gap: var(--column-gap);
    grid-auto-flow: column;

    margin: 0.5rem;
    font-size: 1rem;
}

.auswahlliste{
    display: flex;
    flex-direction: column;
    gap: var(--row-gap);
    max-height: calc(100vh - 1rem - 3.4rem);
    overflow: hidden;
    margin-top: 3.4rem;
}
.auswahllisteLine {
    padding:0.5rem;
    cursor: grab;
    background-color: #ccc;
}
.auswahllisteLine p{
    margin: 0;
}
.auswahllisteLine:hover, .auswahllisteLine:focus{
    /*outline: 1px solid #777;*/
    background-color: #bbb;
}
.neueKategorie.auswahllisteLine:hover, .neueKategorie.auswahllisteLine:focus{
    /*outline: 1px solid #777;*/
    background-color: #444;
}

.eventoKurseTitle, .eventoKurseTitle:focus, .eventoKurseTitle:hover{
    background-color: #fff;
    cursor: default;
    padding: 0;
}
.eventoKurse{
    display: flex;
    flex-direction: column;
    gap: var(--row-gap);
    margin: 0;
    overflow-y:auto;
    --display: contents;
}
.eventoKurs{

}
.neuesElement{

}
.neueKategorie{
    background-color: #666;
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
}
.neueKategorie.auswahllisteLine:hover, .neueKategorie.auswahllisteLine:focus{
    /*outline: 1px solid #777;*/
    background-color: #444;
}
.neueGruppe{
    background-color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    outline: #ccc solid 2px;
    outline-offset: -2px;
}
.neueGruppe.auswahllisteLine:hover, .neueGruppe.auswahllisteLine:focus{
    /*outline: 1px solid #777;*/
    background-color: #ddd;
}
.neuerKurs{
    margin-left: 1rem;
}

.bpColumn {
    grid-column: 1;
    display: flex;
    flex-direction: column;
    gap: var(--row-gap);
}
.bpColumn.bpc2 {
    grid-column: 2;
}
.bpColumn.bpc3 {
    grid-column: 3;
}
.bpColumn.bpc4 {
    grid-column: 4;
}

@media (max-width: 1250px) {
    .bpMain {
        grid-template-columns: repeat(2,1fr);
    }
    .bpColumn.bpc2 {
        grid-column: 1;
    }
    .bpColumn.bpc3 {
        grid-column: 2;
    }
    .bpColumn.bpc4 {
        grid-column: 2;
    }
}

/*@media (max-width: 625px) {*/
    .bpMain {
        grid-template-columns: repeat(1,1fr);
    }
    .bpColumn.bpc2 {
        grid-column: 1;
    }
    .bpColumn.bpc3 {
        grid-column: 1;
    }
    .bpColumn.bpc4 {
        grid-column: 1;
    }
/*}*/

.bpCategory {
    display: contents;
    cursor: grab;
}

.bpCategory .planer-theme-grouping:last-of-type .planer-line:last-child {
    /*color: red;*/
    margin-bottom: 1rem;
}

.bpGroup {
    display: contents;
}

.planer-line {
    grid-column: var(--cat-column);
}

.planer-line p{
    margin: 0.5rem;
}

.planer-line-addition{
    display: inline-block;
    position: absolute;
    right: 2rem;
}
.planer-line-addition-content {
    display: inline-block;
    position: absolute;
    right: 9.5rem;
    color: #444;
}

.planer-spalte-title {
    position: relative;
    color: grey;
    font-weight: bold;
    font-size: 1.2em;
}

.planer-category-title{
    position:relative;
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    background-color: var(--cat-color);
}

.planer-group-title{
    position:relative;
    font-weight: bold;
    font-size: 1.2em;
    background-color: white;
}

.planer-theme-grouping{
    display: contents;
}

.planer-entry{
    position:relative;
    cursor: grab;
    background-color: #ccc;
}

.planer-entry-subpoint{
    margin-left: 1rem;
}

.planer-entry:hover, .planer-entry:focus{
    /*outline: 1px solid #777;*/
    background-color: #bbb;
    background-color: color-mix(in srgb, #ccc 80%, var(--cat-color));
}

.planer-entry::after{
    content: '';
    background-color: var(--cat-color);
    display: block;
    width: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}



/* SVG Buttons */
.imgButton {
    display: inline-block;
    transform: translateY(0.11rem);
    min-height:1rem;
    max-height:1rem;
    min-width: 1rem;
    max-width: 1rem;
    cursor: pointer;
    margin: 0 0.25rem;
}
.imgButton.toggle {
    min-width: 2rem;
    max-width: 2rem;
}
svg.papierkorb,
svg.editieren{
    fill:currentcolor;
    /*stroke:#ccc;
    stroke-width:0.4;*/
}
svg.editieren.disabled, svg.editieren.disabled:hover {
    fill: rgba(0, 0, 0, 0.1);
    cursor: grab;
}
.imgButton svg.editieren.disabled:hover, .imgButton svg.editieren.disabled:hover path.background {
    fill: none;
    stroke: none;
    stroke-width: 0;
}
svg.toggle path.background{
    fill:currentcolor;
    /*stroke:#ccc;
    stroke-width:0.2;*/
}
svg.toggle path.nob{
    fill:#080;
    /*stroke:#ccc;
    stroke-width:0.2;*/
    transform: translateX(0px);
}
.imgButton svg:hover,
.imgButton svg:hover path.background{
    fill:color-mix(in srgb, currentcolor, #888 70%);
    stroke:currentcolor;
    stroke-width:0.015rem;
}
svg.toggle path.nob{
    transition: transform 0.1s ease-in-out, fill 0.1s ease-in-out;
}
svg.toggle.off path.nob{
        fill:#888;
        transform: translateX(-10.6px);
}

div.planer-linie:has(p .planer-line-addition .toggle.off)
{
    /* todo */
    outline: red solid 1px;
}


.modalBG{
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBox{
    background-color: #fff;
    min-width: 50vw;
    m-in-height:50vh;
    gap: 2rem;
    padding: 2rem;
    display:grid;
    grid-template-columns: 1fr 4fr;
    max-width: 80%;
}
.modalBox h2, .modalBox .full-width {
    grid-column: 1 / 3;
}

.modalBox .buttons{
    grid-column: 1 / 3;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2rem;
    
}
.modalBox .buttons button{
    padding:1rem;
    min-width: fit-content;
    width: 10rem;
}
.modalBox .htmleditor-wrapper{
    margin-bottom: 5rem;
}
.modalBox .htmleditor{
    background-color: #eee;
}

.can-drop {
    position: relative;
}

.can-drop::before {
    content: '↪';
    position: absolute;
    top: 10px;
    left: -10px;
    font-size: 16px;
    color: black;
}

.can-drop.is-over::before {
    font-size: 20px;
}

.spalte-tabs {
    display: flex;
    border-bottom: 1px solid #888888;
}

.spalte-tab {
    padding: 10px 20px;
    border: 1px solid transparent;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    cursor: default;
}

.spalte-tab.active {
    background-color: gray;
    color: white;
    border-color: gray;
}

.spalte-tab.can-drop::before {
    content: inherit;
}

.spalte-tab.is-over:not(.active) {
    color: black;
}

.spalte-tab.is-over.active {
    background-color: black;
}

.planer-entry-evento-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    top: 2px;
    position: relative;
}

.evento-tags span {
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    display: inline-block;
    padding: 3px 8px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 50px;
    background-color: #007bff;
    margin: 0 5px 0 0;
}
.evento-tags span a {
    text-decoration: none;
    color: white;
}

.evento-tags span.tag-AreaOfEducation {
    background-color: #ad5050;
}
.evento-tags span.tag-EventCategory {
    background-color: #40ab40;
}
.evento-tags span.tag-EventLevel {
    background-color: #5e5ec5;
}
.evento-tags span.tag-EventType {
    background-color: #96964a;
}

.evento-tags-display {
    position: relative;
    top: -20px;
}
